import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  ApplyInstructionsType,
  IOpportunity,
  OpportunityLocationType,
  OpportunityType,
} from 'global/interfaces/opportunity';

import { pushToDataLayer } from 'utils/tagHelper';
import { viewOpportunity } from 'global/constants';
import { opportunityViewed } from 'services/opportunityService';
import { AccessTime } from '@mui/icons-material';
import ShareButton from 'components/ShareButton';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { UserRole } from 'global/enums/userRole';

interface StyledAccordionProps extends AccordionProps {
  open?: boolean;
}

const StyledAccordion = styled(Accordion)<StyledAccordionProps>(({ theme, open }) => ({
  marginBottom: '16px',
  boxShadow: '0px 2px 12px 0px #1721551A',

  '&.MuiPaper-root': {
    borderRadius: '7px',
    padding: '16px',

    '&:before': {
      display: 'none',
    },
  },

  '&:last-of-type': {
    marginBottom: 0,
  },

  '& .MuiAccordionSummary-root': {
    cursor: open ? 'default' : 'pointer',
    '&.Mui-expanded, &.Mui-expanded:hover': {
      cursor: open ? 'default !important' : 'pointer',
    },

    '&:hover.Mui-expanded': {
      cursor: open ? 'default !important' : 'pointer',
    },
  },

  [theme.breakpoints.up('md')]: {
    '&.MuiPaper-root': {
      '&:hover': {
        borderColor: !open ? '#4B56D8' : 'default',
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    '&.MuiPaper-root': {
      borderRadius: '8px',
      padding: '8px',
    },
  },
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  'svg[data-testid="ExpandMoreIcon"]': {
    display: 'inline-block',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandMoreIcon"]': {
    display: 'none',
  },
  'svg[data-testid="ExpandLessIcon"]': {
    display: 'none',
    position: 'relative',
  },
  '.Mui-expanded svg[data-testid="ExpandLessIcon"]': {
    display: 'inline-block',
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '24px',

  backgroundColor: theme.palette.black[200],
  borderRadius: '16px',
  color: theme.palette.black[500],
  cursor: 'pointer',
  padding: '4px 4px',
}));

const StyledEmailChip = styled(StyledChip)(() => ({
  backgroundColor: '#b6c3df',
}));

const StyledShouttChip = styled(StyledChip)(() => ({
  backgroundColor: '#E7E2FE',
}));

interface IOpportunityAccordionProps {
  loggedIn?: boolean;
  opportunity: IOpportunity;
  expanded?: boolean;
  children: React.ReactNode;
}

export default function OpportunityAccordion({
  opportunity,
  loggedIn,
  expanded,
  children,
}: IOpportunityAccordionProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;
  const baseUrl = hasAdminRole
    ? process.env.REACT_APP_ADMIN_SHARE_OPPORTUNITY_URL
    : process.env.REACT_APP_SHARE_OPPORTUNITY_URL;
  let shareUrl = `${baseUrl}?opportunityId=${opportunity.id}`;

  if (!hasAdminRole && authContext.user?.id) {
    shareUrl = shareUrl + `&source=${authContext.user.id}`;
  } else if (!hasAdminRole) {
    shareUrl = shareUrl + `&source=nou`;
  }

  const onExpandContractOpportunity = (opportunity: IOpportunity, expanded: boolean): void => {
    if (expanded) {
      pushToDataLayer(viewOpportunity, {
        transaction_id: opportunity.id,
      });
      if (loggedIn) {
        opportunityViewed(opportunity.id);
      }
    }
  };

  return (
    <StyledAccordion
      elevation={10}
      disableGutters
      onChange={(_: any, expanded: boolean) => onExpandContractOpportunity(opportunity, expanded)}
      expanded={expanded}
      open={expanded}
    >
      <StyledAccordionSummary>
        <JustifiedBox>
          <Stack direction="column" gap={isMobile ? 0.75 : 1} width="100%">
            <Stack direction="row" justifyContent={isMobile ? 'space-between' : 'flex-start'}>
              <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }} component={'div'}>
                {opportunity.title}
                {opportunity.type == OpportunityType.Shoutt && !isMobile && (
                  <StyledShouttChip
                    label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
                {opportunity.type == OpportunityType.External && !isMobile && (
                  <>
                    {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                      <StyledEmailChip
                        label={<Typography variant={'caption'}>Apply via Email</Typography>}
                        size="small"
                        sx={{ ml: 1 }}
                      />
                    ) : (
                      <Tooltip
                        arrow
                        enterTouchDelay={0}
                        title="We have sourced this opportunity from an external site. We spend 40+ hours a week curating opportunities from over 30 sources so you don't have to."
                        componentsProps={{
                          tooltip: {
                            sx: {
                              maxWidth: '320px',
                              borderRadius: '12px',
                            },
                          },
                        }}
                      >
                        <StyledChip
                          label={<Typography variant={'caption'}>External</Typography>}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </Tooltip>
                    )}
                  </>
                )}
              </Typography>
              <Stack mt="-2px">
                <ShareButton url={shareUrl} />
              </Stack>
            </Stack>

            {opportunity.type == OpportunityType.Shoutt && isMobile && (
              <Box>
                <StyledShouttChip
                  label={<Typography variant={'caption'}># Shoutt Exclusive</Typography>}
                  size="small"
                />
              </Box>
            )}
            {opportunity.type == OpportunityType.External && isMobile && (
              <Box>
                {opportunity.applyInstructionsType === ApplyInstructionsType.Text ? (
                  <StyledEmailChip label={<Typography variant={'caption'}>Apply via Email</Typography>} size="small" />
                ) : (
                  <StyledChip label={<Typography variant={'caption'}>External</Typography>} size="small" />
                )}
              </Box>
            )}

            <Typography
              variant="body2"
              color={theme.palette.grey[500]}
              component={Box}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
            >
              {opportunity.categories.map((category: string) => (
                <>
                  {category}&nbsp;&nbsp;{'•'}&nbsp;&nbsp;
                </>
              ))}
              {opportunity.locationType == OpportunityLocationType.Remote ? 'Remote' : opportunity.location}
              {opportunity.budget && (
                <>
                  &nbsp;&nbsp;{'•'}&nbsp;&nbsp;
                  {opportunity.budget}
                </>
              )}
            </Typography>

            {isMobile && (
              <Stack direction={'row'} gap={0.5} minWidth={'100px'} mt={0.5}>
                <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
                <Typography variant="body2" color={theme.palette.grey[500]}>
                  {opportunity.agoDesc}
                </Typography>
              </Stack>
            )}
          </Stack>
          {!isMobile && (
            <Stack direction={'row'} gap={0.5} minWidth={'100px'}>
              <AccessTime sx={{ stroke: theme.palette.grey[300] }} />
              <Typography variant="body2" color={theme.palette.grey[500]}>
                {opportunity.agoDesc}
              </Typography>
            </Stack>
          )}
        </JustifiedBox>
      </StyledAccordionSummary>

      <AccordionDetails>
        <Stack direction="column" spacing={0} width="100%" gap={3}>
          <Typography variant={isMobile ? 'body2' : 'body1'} whiteSpace="break-spaces">
            {opportunity.description}
          </Typography>
          <Grid container gap={2}>
            {children}
          </Grid>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
}
