import { useEffect } from 'react';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

interface GTMProps {
  gtmId: string;
}

const GTMScript: React.FC<GTMProps> = ({ gtmId }) => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    // Load GTM script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;

    // Initialize GTM
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    // Add script to head
    document.head.appendChild(script);

    return () => {
      // Check if script still exists before removing
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [gtmId]); // Added gtmId to dependency array

  return null;
};

export default GTMScript;
