import ReactDOM from 'react-dom';
import App from './App';
import About from 'views/About';
import Talent from 'views/talent/Talent';
import Login from 'views/Login';
import AccountConfirmation from 'views/signup/AccountConfirmation';
import StripeReAuth from 'views/payouts/StripeReAuth';
import ResetPassword from 'views/passwords/ResetPassword';
import ForgotPassword from 'views/passwords/ForgotPassword';
import ChangePassword from 'views/passwords/ChangePassword';
import ViewContract from 'views/contracts/ViewContract';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PayoutDetails from 'views/payouts/PayoutDetails';
import WorkRoom from 'views/workroom/WorkRoom';
import SignupRenderer from 'views/signup/SignupRenderer';
import AdminUsers from 'views/adminUsers/AdminUsers';
import AdminUser from 'views/adminUsers/AdminUser';
import AdminSkills from 'views/adminSkills/AdminSkills';
import ViewUser from 'views/users/ViewUser';
import UserSubmitted from 'views/users/UserSubmitted';
import ViewContracts from 'views/contracts/ViewContracts';
import ViewUsers from 'views/users/ViewUsers';
import EditUser from 'views/users/EditUser';
import { ProtectedRoute } from 'components/common/routes/ProtectedRoute';
import { UserRole } from 'global/enums/userRole';
import { ForcedLoginRoute } from 'components/common/routes/ForcedLoginRoute';
import Dispute from 'views/dispute/Dispute';
import BillingAddress from 'views/billing/BillingAddress';

import ViewNetworkConnections from 'views/network/connections/ViewNetworkConnections';
import ViewNetworkInvites from 'views/network/invites/ViewNetworkInvites';
import Terms from 'views/terms';
import Policy from 'views/Policy';
import DesignSystem from 'views/DesignSystem';
import Unsubscribe from 'views/Unsubscribe';
import ContactUs from 'views/ContactUs';
import NotFound from 'NotFound';
import SourceTracking from 'views/SourceTracking';
import RedirectUrl from 'components/common/RedirectUrl';
import PaymentDetailsUpdated from 'views/contracts/PaymentDetailsUpdated';
import Dashboard from 'views/dashboard/Dashboard';
import Home from 'views/Home';
import AdminUserEdit from 'views/adminUsers/AdminUserEdit';
import Subscriptions from 'views/subscriptions';
import NewJob from 'views/opportunities/NewJob';
import AccountType from 'views/signup/AccountType';
import Partner from 'views/partner/Partner';
import NewExternalJob from 'views/adminOpportunities/NewExternalJob';
import ViewOpportunities from 'views/opportunities/ViewOpportunities';
import ViewAdminOpporunities from 'views/adminOpportunities/ViewAdminOpportunities';
import { EditOpportunity } from 'views/opportunities/EditOpportunity';
import AccountCreated from 'views/users/AccountCreated';
import Pricing from 'views/pricing/Pricing';
import ViewConnectionAttempts from 'views/adminConnectionAttempts/ViewConnectionAttempts';
import ViewOpportunity from 'views/opportunities/ViewOpportunity';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="talent" element={<Talent />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="signup" element={<AccountType />} />
        <Route path="signup/details" element={<SignupRenderer />} />
        <Route path="signup/verification" element={<SignupRenderer />} />
        <Route path="about" element={<About />} />
        <Route path="privacy" element={<Policy />} />
        <Route path="partner" element={<Partner />} />
        <Route path="opportunities" element={<ViewOpportunities />} />
        <Route path="/opportunities/:id" element={<ViewOpportunity />} />
        <Route path="moderation" element={<RedirectUrl url={process.env.REACT_APP_MODERATION_URL ?? ''} />} />
        <Route path="login" element={<Login />} />
        <Route path="newjob" element={<NewJob />} />
        <Route path="password/reset" element={<ResetPassword />} />
        <Route path="users" element={<ViewUsers />} />
        <Route element={<ForcedLoginRoute />}>
          <Route path="users/:id" element={<ViewUser />} />
        </Route>
        <Route path="password/forgot" element={<ForgotPassword />} />
        <Route path="password/change" element={<ChangePassword />} />
        <Route path="confirmation" element={<AccountConfirmation />} />
        <Route path="/info" element={<RedirectUrl url={'/opportunities'} />} />
        <Route path="/info/vid" element={<RedirectUrl url={'/opportunities'} />} />
        <Route path="/info/video" element={<RedirectUrl url={'/opportunities'} />} />
        <Route path="/terms/seller" element={<Terms />} />
        <Route path="/terms/buyer" element={<Terms />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/designsystem" element={<DesignSystem />} />
        <Route path="/sourcetracking" element={<SourceTracking />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/paymentdetailsupdated" element={<PaymentDetailsUpdated />} />

        <Route element={<ProtectedRoute />}>
          <Route path="users/:id/edit" element={<EditUser />} />
          <Route path="users/:id/submitted" element={<UserSubmitted />} />
          <Route path="users/:id/created" element={<AccountCreated />} />
          <Route path="payout" element={<PayoutDetails />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="payout/stripe" element={<StripeReAuth />} />
          <Route path="workroom/" element={<WorkRoom />} />
          <Route path="workroom/:id" element={<WorkRoom />} />
          <Route path="contracts" element={<ViewContracts />} />
          <Route path="contracts/:id" element={<ViewContract />} />
          <Route path="network/connections" element={<ViewNetworkConnections />} />
          <Route path="network/invites" element={<ViewNetworkInvites />} />
          <Route path="disputes" element={<Dispute />} />
          <Route path="billingaddress" element={<BillingAddress />} />
          <Route path="disputes/:id" element={<Dispute />} />
          <Route path="subscriptions" element={<Subscriptions />} />
        </Route>

        <Route path="admin" element={<ProtectedRoute role={UserRole.Administrator} />}>
          <Route path="users" element={<AdminUsers />} />
          <Route path="users/:id" element={<AdminUser />} />
          <Route path="users/:id/edit" element={<AdminUserEdit />} />
          <Route path="skills" element={<AdminSkills />} />
          <Route path="newexternaljob" element={<NewExternalJob />} />
          <Route path="internal-jobs" element={<ViewAdminOpporunities />} />
          <Route path="internal-jobs/:id" element={<EditOpportunity />} />
          <Route path="connectionattempts" element={<ViewConnectionAttempts />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Width detection is a fix for this: https://dev.azure.com/shoutt/Shoutt/_workitems/edit/572
// Based on: https://medium.com/@ibalajisankar/resize-event-issue-in-mobile-phones-874a8fc8c036#:~:text=The%20issue%20is%20because%20of,any%20change%20in%20screen%20width.

reportWebVitals({});
let screenWidth = 0;
const appHeight = (): void => {
  if (screenWidth != window.innerWidth) {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    screenWidth = window.innerWidth;
  }
};
window.addEventListener('resize', appHeight);
appHeight();
