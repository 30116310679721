import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useFormik } from 'formik';
import { INewOpportunityForm, IOpportunity, OpportunityLocationType } from 'global/interfaces/opportunity';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'contexts/AuthContext';

import { useLocalization } from 'global/hooks/useLocalization';
import NewOpportunitySteps from './newOpportunitySteps';
import NewOpportunityStepCarousel from './newOpportunityStepCarousel';
import { opportunityValidationSchema } from 'global/validations/opportunity';
import { postNewOpportunity, updateOpportunity } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import CategoryMultiSelect from 'components/common/Select/CategoryMultiSelect';

import { BannerStack, FeatureSubText, FeatureText, MultiLineFeatureContainer } from 'components/info/Hero';
import { pushToDataLayer } from 'utils/tagHelper';
import { postOpportunity } from 'global/constants';

import { ClockIcon } from 'components/icon/ClockIcon';

import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import { BriefIcon } from 'components/icon/BriefIcon';

import im from 'assets/images/lps/Hero_submit_opportunity_min.webp';

const StyledBannerBox = styled(Box)(() => ({
  backgroundImage: `url(${im})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '72px',

  [theme.breakpoints.down('sm')]: {
    marginTop: '32px',
  },
}));

const StyledFormGrid = styled(Grid)(() => ({
  marginTop: '8px',
}));

const StyledButtonGrid = styled(Grid)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));

const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '14px',
  color: theme.palette.error.main,
}));

export default function OpportunityForm({
  setSubmitted,
  isExternal,
  opportunity,
}: {
  setSubmitted: Dispatch<boolean>;
  isExternal: boolean;
  opportunity?: IOpportunity;
}) {
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol } = useLocalization(29);
  const navigate = useNavigate();

  const handleCancelClick = (): void => {
    navigate(-1);
  };

  const form = useFormik<INewOpportunityForm>({
    initialValues: {
      name: opportunity?.name ?? '',
      description: opportunity?.description ?? '',
      email: opportunity?.email ?? '',
      title: opportunity?.title ?? '',
      budget: opportunity?.budget ?? '',
      token: '',
      userId: opportunity?.userId ?? undefined,
      isExternal: isExternal,
      applyInstructions: opportunity?.applyInstructions ?? undefined,
      categories: opportunity?.categories ?? [],
      locationType: opportunity?.locationType ?? OpportunityLocationType.Remote,
      location: opportunity?.location ?? undefined,
    },
    onSubmit: values => {
      setLoading(true);
      if (!opportunity) {
        postNewOpportunity(values)
          .then(() => {
            if (!isExternal) {
              pushToDataLayer(postOpportunity);
            }
            setSubmitted(true);
          })
          .catch(showError)
          .finally(() => setSubmitted(true));
      }

      if (opportunity) {
        updateOpportunity(values, opportunity.id)
          .catch(showError)
          .finally(() => setSubmitted(false));
      }
    },
    validationSchema: opportunityValidationSchema(!isLoggedIn),
  });

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldValue(
        nameof<INewOpportunityForm>(x => x.name),
        authContext.user?.firstName,
      );
      form.setFieldValue(
        nameof<INewOpportunityForm>(x => x.email),
        authContext.user?.email,
      );
      form.setFieldValue(
        nameof<INewOpportunityForm>(x => x.userId),
        authContext.user?.id,
      );
    }
  }, [authContext.user]);

  return (
    <>
      {!isExternal && isMobile && <NewOpportunityStepCarousel currencySymbol={localCurrencySymbol} />}
      <StyledBannerBox>
        <Container maxWidth="lg">
          {isExternal ? (
            <BannerStack direction={'column'} rowGap={2} alignItems={'center'}>
              <Typography
                variant="h3"
                fontSize={{ xs: '28px', md: '40px', lg: '56px' }}
                color={theme.palette.common.black}
                maxWidth={'700px'}
                textAlign={'center'}
              >
                Post an External Opportunity
              </Typography>
            </BannerStack>
          ) : (
            <BannerStack direction={'column'} rowGap={2}>
              <Grid container columnSpacing={6}>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    fontSize={{ xs: '26px', md: '44px', lg: '55px' }}
                    color={'#101828'}
                    maxWidth={'800px'}
                  >
                    Hire great freelance talent - skip the noise.
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize={{ xs: '16px', lg: '20px' }}
                    color={'#344054'}
                    maxWidth={'800px'}
                    mt={isMobile ? 2 : 3}
                  >
                    Post your opportunity for FREE today. Only vetted freelancers with proven experience can respond, so
                    your time is not wasted.
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mt={{ xs: '0px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
                <Grid item xs={12} md={4}>
                  <MultiLineFeatureContainer direction={'row'}>
                    <Stack direction={'column'} gap={'4px'} spacing={1}>
                      <Stack direction={'row'} spacing={1}>
                        <BriefIcon
                          sx={{
                            width: !isMobile ? '24px' : '20px',
                            height: !isMobile ? '24px' : '20px',
                          }}
                          htmlColor="#ED79D5"
                        />

                        <FeatureText>100% Commission Free</FeatureText>
                      </Stack>

                      <FeatureSubText>
                        We’re 100% commission free. So your talent keeps all of their earnings, and you pay nothing
                        extra on top.
                      </FeatureSubText>
                    </Stack>
                  </MultiLineFeatureContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MultiLineFeatureContainer direction={'row'}>
                    <Stack direction={'column'} gap={'4px'} spacing={1}>
                      <Stack direction={'row'} spacing={1}>
                        <ClockIcon
                          sx={{
                            width: !isMobile ? '24px' : '20px',
                            height: !isMobile ? '24px' : '20px',
                          }}
                          htmlColor="#ED79D5"
                        />
                        <FeatureText>No Obligations</FeatureText>
                      </Stack>

                      <FeatureSubText>
                        There are no contracts or obligations to worry about. It only takes around 5m to post a new
                        opportunity.
                      </FeatureSubText>
                    </Stack>
                  </MultiLineFeatureContainer>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MultiLineFeatureContainer direction={'row'} height={'100%'}>
                    <Stack direction={'column'} gap={'4px'} spacing={1}>
                      <Stack direction={'row'} spacing={1}>
                        <ExclusiveIcon
                          sx={{
                            width: !isMobile ? '24px' : '20px',
                            height: !isMobile ? '24px' : '20px',
                          }}
                          htmlColor="#ED79D5"
                        />
                        <FeatureText>Payment Protection</FeatureText>
                      </Stack>

                      <FeatureSubText>
                        Take advantage of our invoice, payment protection and contract tools. Or deal direct. It’s your
                        choice.
                      </FeatureSubText>
                    </Stack>
                  </MultiLineFeatureContainer>
                </Grid>
              </Grid>
            </BannerStack>
          )}
        </Container>
      </StyledBannerBox>
      <StyledContainer maxWidth={isExternal ? 'md' : 'lg'}>
        <form onSubmit={form.handleSubmit}>
          <Grid container>
            {!isExternal && !isMobile && (
              <Grid item xs={0} md={4}>
                <NewOpportunitySteps currencySymbol={localCurrencySymbol} />
              </Grid>
            )}

            <Grid container item xs={12} md={isExternal ? 12 : 8}>
              {!isExternal && (
                <Grid container item xs={12}>
                  <Stack gap={0.5} mt={1}>
                    <Typography variant="body2">
                      Submit your project brief and we’ll get your opportunity in front of qualified freelancers for
                      free. All talent on Shoutt is pre-screened by us to help you save time and deliver great work with
                      minimal fuss.
                    </Typography>
                  </Stack>
                </Grid>
              )}

              <StyledFormGrid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant={'h6'}>Basic Info</Typography>
                </Grid>
                {!isExternal && (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl>
                          <TextField
                            name={nameof<INewOpportunityForm>(x => x.name)}
                            label="Your Name *"
                            value={form.values.name}
                            variant="outlined"
                            fullWidth
                            onChange={form.handleChange}
                            error={form.touched.name !== undefined && Boolean(form.errors.name)}
                            helperText={form.touched.name !== undefined ? form.errors.name : ''}
                            disabled={isLoggedIn}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormGroup>
                        <FormControl>
                          <TextField
                            name={nameof<INewOpportunityForm>(x => x.email)}
                            label="Email *"
                            variant="outlined"
                            fullWidth
                            value={form.values.email}
                            onChange={form.handleChange}
                            error={form.touched.email !== undefined && Boolean(form.errors.email)}
                            helperText={form.touched.email !== undefined ? form.errors.email : ''}
                            disabled={isLoggedIn}
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<INewOpportunityForm>(x => x.title)}
                        label="Title *"
                        variant="outlined"
                        fullWidth
                        value={form.values.title}
                        placeholder="i.e Freelance Designer for new Saas Product"
                        onChange={form.handleChange}
                        error={form.touched.title !== undefined && Boolean(form.errors.title)}
                        helperText={form.touched.title !== undefined ? form.errors.title : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h6'} mt={2}>
                    Project Details
                  </Typography>
                  <Typography variant="body2" mt="4px">
                    Please describe the freelancer&apos;s responsibilities, your niche, expected deliverables, and
                    timeline.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<INewOpportunityForm>(x => x.description)}
                        variant="outlined"
                        fullWidth
                        rows={10}
                        multiline
                        onChange={form.handleChange}
                        value={form.values.description}
                        error={form.touched.description !== undefined && Boolean(form.errors.description)}
                        helperText={form.touched.description !== undefined ? form.errors.description : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h6'} mt={2}>
                    Categories
                  </Typography>
                  <Typography variant="body2" mt="4px">
                    Please choose 1 or more categories that best describe the project.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <CategoryMultiSelect
                    value={form.values.categories}
                    homeCategoriesOnly={!isExternal}
                    label=""
                    onChange={changedCategories =>
                      form.setFieldValue(
                        nameof<INewOpportunityForm>(x => x.categories),
                        changedCategories.target.value,
                      )
                    }
                    input={<OutlinedInput id="select-multiple-chip" label="Categories" />}
                    error={form.touched.categories !== undefined && Boolean(form.errors.categories)}
                    helpertext={form.errors.categories ?? ''}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant={'h6'} mt={2}>
                    Location
                  </Typography>
                  <Typography variant="body2" mt="4px">
                    Is this opportunity fully remote?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    name={nameof<INewOpportunityForm>(x => x.locationType)}
                    value={form.values.locationType}
                    onChange={form.handleChange}
                    row
                  >
                    <FormControlLabel value={OpportunityLocationType.Remote} control={<Radio />} label="Yes" />
                    <FormControlLabel value={OpportunityLocationType.Local} control={<Radio />} label="No" />
                  </RadioGroup>
                  <FormHelperText error>{form.errors.locationType}</FormHelperText>
                </Grid>
                {form.values.locationType == OpportunityLocationType.Local && (
                  <Grid item xs={12} md={12}>
                    <FormGroup>
                      <FormControl>
                        <TextField
                          name={nameof<INewOpportunityForm>(x => x.location)}
                          label="Location *"
                          variant="outlined"
                          fullWidth
                          value={form.values.location}
                          placeholder="i.e City"
                          onChange={form.handleChange}
                          error={form.touched.location !== undefined && Boolean(form.errors.location)}
                          helperText={form.touched.location !== undefined ? form.errors.location : ''}
                        />
                      </FormControl>
                    </FormGroup>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant={'h6'} mt={2}>
                    Budget
                  </Typography>
                  <Typography variant="body2" mt="4px">
                    Please indicate an approx budget or rate for this project.
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name={nameof<INewOpportunityForm>(x => x.budget)}
                        label={isExternal ? 'Budget' : 'Budget *'}
                        variant="outlined"
                        fullWidth
                        value={form.values.budget}
                        onChange={form.handleChange}
                        error={form.touched.budget !== undefined && Boolean(form.errors.budget)}
                        helperText={form.touched.budget !== undefined ? form.errors.budget : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                {isExternal && (
                  <>
                    <Grid item xs={12}>
                      <Typography variant={'h6'} mt={2}>
                        Apply Instructions
                      </Typography>
                      <Typography variant="body2" mt="4px">
                        A url that starts with http or text that describes how to apply.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <FormGroup>
                        <FormControl>
                          <TextField
                            name={nameof<INewOpportunityForm>(x => x.applyInstructions)}
                            label=""
                            variant="outlined"
                            fullWidth
                            value={form.values.applyInstructions}
                            placeholder="https://...."
                            onChange={form.handleChange}
                            error={
                              form.touched.applyInstructions !== undefined && Boolean(form.errors.applyInstructions)
                            }
                            helperText={
                              form.touched.applyInstructions !== undefined ? form.errors.applyInstructions : ''
                            }
                          />
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </>
                )}

                {!isLoggedIn && (
                  <Grid item>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
                      onChange={(value: string | null) => {
                        form.setFieldValue(
                          nameof<INewOpportunityForm>(x => x.token),
                          value,
                        );
                      }}
                      onErrored={() => console.error('reCAPTCHA initialization error')}
                    />
                    {form.errors.token && form.submitCount > 0 && (
                      <StyledHelperText>{form.errors.token}</StyledHelperText>
                    )}
                  </Grid>
                )}

                <StyledButtonGrid container item xs={12} justifyContent={'flex-end'}>
                  <Grid container item xs={12} sm={6} spacing={1}>
                    <Grid item xs={6}>
                      <RoundButton variant="outlined" fullWidth onClick={handleCancelClick}>
                        Cancel
                      </RoundButton>
                    </Grid>
                    <Grid item xs={6}>
                      <RoundButton loading={loading} variant="contained" type="submit" fullWidth>
                        Submit
                      </RoundButton>
                    </Grid>
                  </Grid>
                </StyledButtonGrid>
              </StyledFormGrid>
            </Grid>
          </Grid>
        </form>
      </StyledContainer>
    </>
  );
}
