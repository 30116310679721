import { Box, Container, Typography } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import { IOpportunity } from 'global/interfaces/opportunity';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOpportunity } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import OppNotFound from './OppNotFound';
import EastIcon from '@mui/icons-material/East';
import { AuthContext } from 'contexts/AuthContext';
import { pushToDataLayer } from 'utils/tagHelper';
import { applyOpportunityLogin } from 'global/constants';
import { redirectToSignUpSeller } from 'utils/router';
import ApplyDialog from 'components/opportunities/ApplyDialog';

export default function ViewOpportunity() {
  const [opp, setOpp] = useState<IOpportunity>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const authContext = useContext(AuthContext);
  const loggedIn: boolean = authContext.user !== null;

  useEffect(() => {
    if (!id) return;

    getOpportunity(id)
      .then(res => setOpp(res))
      .catch(e => {
        if (e.status === 404) {
          setNotFound(true);
          return;
        }
        showError(e);
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (notFound) {
    return <OppNotFound />;
  }

  const applyNow = (opportunity: IOpportunity): void => {
    if (!loggedIn) {
      pushToDataLayer(applyOpportunityLogin, {
        transaction_id: opportunity.id,
      });
      location.state = {
        ...location.state,
        opportunityId: opportunity.id,
      };
      redirectToSignUpSeller(navigate, location);
    } else {
      setApplyDialogOpen(true);
    }
  };

  const onApplyClose = (): void => {
    setApplyDialogOpen(false);
  };

  return (
    <>
      {opp && !loading && (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Box mb={3}>
            <Link to="/opportunities" style={{ textDecoration: 'none' }}>
              <Typography variant="subtitle2" color="primary">
                View All Opportunities
              </Typography>
            </Link>
          </Box>
          <OpportunityAccordion opportunity={opp} loggedIn={loggedIn} expanded={true}>
            <RoundButton
              variant="contained"
              endIcon={<EastIcon />}
              onClick={() => {
                applyNow(opp);
              }}
            >
              Apply Now
            </RoundButton>
          </OpportunityAccordion>
          <ApplyDialog open={applyDialogOpen} opportunity={opp} onClose={onApplyClose} />
        </Container>
      )}
    </>
  );
}
