import { Search } from '@mui/icons-material';
import {
  debounce,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { IOpportunity } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { Dispatch, useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

interface IOppFilterProps {
  category: string | undefined;
  categories: SelectItem[];
  setCategory: Dispatch<string>;
  setFilteredOpps: Dispatch<React.SetStateAction<IOpportunity[]>>;
  opportunities: IOpportunity[];
  children?: React.ReactNode;
}

export default function OppFilters({
  category,
  categories,
  setCategory,
  setFilteredOpps,
  opportunities,
  children,
}: IOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const workerRef = useRef<Worker | null>(null);
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    workerRef.current = new Worker(new URL('./search-worker.ts', import.meta.url));
    workerRef.current.postMessage({
      type: 'BUILD_INDEX',
      data: { opportunities },
    });

    return () => workerRef.current?.terminate();
  }, [opportunities]);

  useEffect(() => {
    const handleWorkerMessage = (event: MessageEvent) => {
      if (event.data.type === 'SEARCH_RESULTS') {
        setFilteredOpps(event.data.data.map((index: number) => opportunities[index]));
      }
    };

    workerRef.current?.addEventListener('message', handleWorkerMessage);

    return () => {
      workerRef.current?.removeEventListener('message', handleWorkerMessage);
    };
  }, [opportunities, setFilteredOpps]);

  const debouncedSearch = useMemo(
    () =>
      debounce((value: string, cat: string) => {
        if ((cat === 'All' && value.length >= 3) || cat !== 'All') {
          workerRef.current?.postMessage({
            type: 'SEARCH',
            data: { searchValue: value, category: cat },
          });
        } else {
          setFilteredOpps(opportunities);
        }
      }, 200),
    [opportunities, setFilteredOpps],
  );

  useEffect(() => debouncedSearch.clear(), [debouncedSearch]);

  const handleCategoryChange = useCallback(
    (e: SelectChangeEvent<unknown>): void => {
      const selectedCat = e.target.value as string;
      setCategory(selectedCat);

      setSearchParams(
        searchParams => {
          const updatedParams = new URLSearchParams(searchParams);
          if (selectedCat) {
            updatedParams.set('category', selectedCat);
          } else {
            updatedParams.delete('category');
          }
          return updatedParams;
        },
        { replace: true },
      );

      debouncedSearch(search, selectedCat);
    },
    [setCategory, debouncedSearch, search],
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const newSearchValue = e.target.value;
      setSearch(newSearchValue);

      setSearchParams(
        searchParams => {
          const updatedParams = new URLSearchParams(searchParams);
          if (newSearchValue) {
            updatedParams.set('oppsearch', newSearchValue);
          } else {
            updatedParams.delete('oppsearch');
          }
          return updatedParams;
        },
        { replace: true },
      );

      if (newSearchValue.trim() === '' && category === 'All') {
        debouncedSearch.clear();
        setFilteredOpps(opportunities);
      } else {
        debouncedSearch(newSearchValue, category || 'All');
      }
    },
    [debouncedSearch, category, opportunities, setFilteredOpps],
  );

  const setSearchOnLoad = useCallback(() => {
    if (opportunities.length === 0) return;
    const search = searchParams.get('oppsearch') ?? '';
    if (searchParams.has('category')) {
      setCategory(searchParams.get('category') ?? '');
    }
    if (searchParams.has('oppsearch')) {
      setSearch(search);
    }
    debouncedSearch(search, category || 'All');
  }, [opportunities, searchParams, setCategory, debouncedSearch, category]);

  useEffect(() => {
    setSearchOnLoad();
  }, [setSearchOnLoad]);

  return (
    <Grid container item xs={12} sx={{ mb: !isMobile ? 5 : 2, mt: 5 }} gap={1}>
      <Grid item xs={12} sm={5} md={3}>
        <FormSelect
          label="Category"
          value={category}
          name="category"
          size={isMobile ? 'medium' : 'small'}
          items={categories}
          onChange={handleCategoryChange}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          name="search"
          label="Search"
          value={search}
          onChange={handleSearchChange}
          autoComplete="off"
          placeholder="Enter Keywords"
          size={isMobile ? 'medium' : 'small'}
          fullWidth
          InputProps={{
            autoComplete: 'off',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" size="small">
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {children}
    </Grid>
  );
}
