import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import { IOpportunity, OpportunityType } from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useLocalization } from 'global/hooks/useLocalization';
import { getBasicPlanPriceFromCurrency } from 'utils/currency';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import { createProductCheckout } from 'services/subscriptionService';
import CloseIcon from '@mui/icons-material/Close';

import { showError } from 'utils/errorHandler';

import ApplyExternalOpportunity from './ApplyExternalOpportunity';
import ApplyShouttOpportunity from './ApplyShouttOpportunity';
import { payWalled } from 'services/opportunityService';
import { pushToDataLayer } from 'utils/tagHelper';
import { applyOpportunityPayWall, beginCheckoutEvent } from 'global/constants';
import DoneIcon from '@mui/icons-material/Done';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useNavigate } from 'react-router-dom';
import userIm from 'assets/images/user/Imogen.png';

interface IApplyDialogProps {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateOpportunity?: (opportunity: IOpportunity) => void;
}

interface PriceFeature {
  text: string;
}

const growFeatures: PriceFeature[] = [
  { text: 'Access to Shoutt exclusive opportunities' },
  {
    text: 'Unlock access to 1000+ external opportunities per month sourced from over 30 sites daily',
  },
  { text: 'Apply to unlimited opportunities & respond to messages' },
];

const freeFeatures: PriceFeature[] = [
  { text: 'Invoice clients and accept payments via card, bank transfer and Apple / Google pay' },
  { text: 'Get paid on time with Shoutt payment protection (commission free)' },

  { text: 'Streamline your work & avoid scope creep with built-in contracts' },
];

const PriceFeatureTypography = styled(Typography)(() => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: '#2B1161',
}));

const TopTypography = styled(PriceFeatureTypography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.common.black,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '0px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '0px 16px !important',
    minHeight: 'calc(100svh - 200px)',
  },
}));

const StyledTopBox = styled(Box)(({ theme }) => ({
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '16px',
  backgroundColor: '#F2F4F7',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
}));

const LinkTypography = styled(PriceFeatureTypography)(() => ({
  color: '#1F4DE2',
  fontWeight: '500',
  cursor: 'pointer',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function ApplyDialog(props: IApplyDialogProps): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { localCurrencySymbol } = useLocalization(19.99);
  const price = getBasicPlanPriceFromCurrency(localCurrencySymbol);

  const authContext = useContext(AuthContext);
  const subscriptionActive: boolean = authContext.user?.subscriptionActive ?? false;
  const [loading, setLoading] = useState(false);

  const [isFinalScreen, setIsFinalScreen] = useState(false);

  const navigate = useNavigate();

  const createSubscriptionSession = () => {
    setLoading(true);
    pushToDataLayer(beginCheckoutEvent, {
      transaction_id: authContext.user?.id,
    });
    createProductCheckout(`${window.location.href}`)
      .then(res => {
        window.location.href = res;
        setLoading(false);
      })
      .catch(showError);
  };

  const updateApplyOnClose = () => {
    props.onClose();
    setTimeout(() => {
      if (props.opportunity) {
        props.opportunity.applied = true;
        props.updateOpportunity && props.updateOpportunity(props.opportunity);
      }
    }, 500);
  };

  useEffect(() => {
    if (props.open && props.opportunity != null && !subscriptionActive) {
      pushToDataLayer(applyOpportunityPayWall, {
        transaction_id: props.opportunity.id,
      });
      payWalled(props.opportunity.id);
    }

    if (props.open) {
      setIsFinalScreen(false);
    }
  }, [props.opportunity, props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
      maxWidth={!subscriptionActive || props.opportunity?.type == OpportunityType.Shoutt ? 'md' : 'sm'}
    >
      {!subscriptionActive && isFinalScreen && (
        <>
          <StyledDialogTitle>
            <Typography variant="h5" color={theme.palette.grey[900]}>
              Go Premium
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent>
            <StyledTopBox>
              {!isMobileScreen && (
                <>
                  <TopTypography variant="body1">“I’ve earned 9k from Shoutt in the past few months”</TopTypography>

                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Avatar
                      sx={{
                        width: '36px',
                        height: '36px',
                        border: '1px solid #A28CF3',
                      }}
                      src={userIm}
                    ></Avatar>

                    <TopTypography variant="body1">
                      <span
                        style={{ textDecoration: 'underline', color: '#2D5CE6' }}
                        onClick={() => {
                          window.open('https://talent.shoutt.co/Share/HcQSO8jtVEGh7TRsW3nq_g', '_blank');
                        }}
                      >
                        Imogen H
                      </span>
                    </TopTypography>
                  </Stack>
                </>
              )}
              {isMobileScreen && (
                <>
                  <TopTypography variant="body1" alignItems={'center'}>
                    “I’ve earned 9k from Shoutt in the past few months” {' - '}
                    <span
                      style={{ textDecoration: 'underline', color: '#2D5CE6' }}
                      onClick={() => {
                        window.open('https://talent.shoutt.co/Share/HcQSO8jtVEGh7TRsW3nq_g', '_blank');
                      }}
                    >
                      Imogen H
                    </span>
                  </TopTypography>
                </>
              )}
            </StyledTopBox>
            <Grid container spacing={1.5}>
              <Grid item xs={12} md={7.5}>
                <Box
                  sx={{
                    padding: '28px',
                    borderRadius: '12px',
                    backgroundColor: '#FEFAEC',
                    paddingBottom: '20px',
                  }}
                >
                  <Stack direction={'row'} spacing={1}>
                    <Typography variant="h4" fontSize={'36px'} fontWeight={600} color={'#2B1161'}>
                      {localCurrencySymbol}
                      {price}
                    </Typography>
                    <Stack direction={'column'}>
                      <Typography variant="body2" color={'#475467'}>
                        per month
                      </Typography>
                      <Typography variant="body2" color={'#475467'}>
                        cancel anytime
                      </Typography>
                    </Stack>
                  </Stack>
                  <Divider
                    sx={{
                      width: '80%',
                      backgroundColor: '#98A2B3',
                      mt: '18px',
                      mb: '24px',
                    }}
                  ></Divider>
                  <Typography variant="body2" color={'#2B1161'} fontWeight={700}>
                    GROW YOUR BUSINESS
                  </Typography>
                  <List>
                    {growFeatures.map((feature: PriceFeature, p: number) => (
                      <ListItem key={p} alignItems="flex-start" disableGutters dense>
                        <StyledListItemIcon>
                          <StyledDoneIcon />
                        </StyledListItemIcon>
                        <ListItemText>
                          <PriceFeatureTypography variant="body1">{feature.text}</PriceFeatureTypography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="body2" color={'#2B1161'} fontWeight={700} mt={1.5}>
                    PLUS ALL THIS FOR FREE!
                  </Typography>
                  <List>
                    {freeFeatures.map((feature: PriceFeature, p: number) => (
                      <ListItem key={p} alignItems="flex-start" disableGutters dense>
                        <StyledListItemIcon>
                          <StyledDoneIcon />
                        </StyledListItemIcon>
                        <ListItemText>
                          <PriceFeatureTypography variant="body1">{feature.text}</PriceFeatureTypography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} md={4.5}>
                <Box
                  sx={{
                    padding: '28px',
                    borderRadius: '12px',
                    backgroundColor: '#F9FAFB',
                    height: '100%',
                  }}
                >
                  <Typography variant="h6" fontSize={'24px'} color={'#2B1161'} fontWeight={600}>
                    Not quite ready?
                  </Typography>
                  {authContext.user?.vettingStatus == VettingStatus.NotSubmitted && (
                    <>
                      <PriceFeatureTypography variant="body1" mt={2.0}>
                        That’s okay! You can get listed on our marketplace for FREE so new clients can find and contact
                        you directly, no bidding required.
                      </PriceFeatureTypography>
                      <LinkTypography
                        variant="body1"
                        mt={2.5}
                        color={'#1F4DE2'}
                        onClick={() => navigate(`/users/${authContext.user?.id}/edit?apply=true`)}
                      >
                        Get listed now
                      </LinkTypography>
                    </>
                  )}
                  {authContext.user?.vettingStatus == VettingStatus.InProgress && (
                    <>
                      <PriceFeatureTypography variant="body1" mt={2.0}>
                        That’s okay! You have submitted your profile for approval to be listed on our marketplace for
                        FREE so new clients can find and contact you directly, no bidding required.
                      </PriceFeatureTypography>
                    </>
                  )}
                  {authContext.user?.vettingStatus == VettingStatus.UpdatesRequired && (
                    <>
                      <PriceFeatureTypography variant="body1" mt={2.0}>
                        That’s okay! You have submitted your profile for approval to be listed on our marketplace but
                        you are required to make updates to your profile to be listed.
                      </PriceFeatureTypography>
                      <LinkTypography variant="body1" mt={2.5} color={'#1F4DE2'} onClick={() => navigate(`/`)}>
                        View feedback
                      </LinkTypography>
                    </>
                  )}
                  {authContext.user?.vettingStatus == VettingStatus.Rejected && (
                    <>
                      <PriceFeatureTypography variant="body1" mt={2.0}>
                        That’s okay! You have submitted your profile for approval to be listed on our marketplace but
                        your profile was rejected. Feel free to apply again in 6 months time.
                      </PriceFeatureTypography>
                    </>
                  )}
                  {authContext.user?.vettingStatus == VettingStatus.Approved && (
                    <>
                      <PriceFeatureTypography variant="body1" mt={2.0}>
                        That’s okay! You are already listed on our marketplace for FREE so new clients can find and
                        contact you directly, no bidding required.
                      </PriceFeatureTypography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
              {!isMobileScreen ? <>No, I&apos;m not ready to find more work</> : <>No, I&apos;m not ready</>}
            </RoundButton>
            <RoundButton
              fullWidth={isMobileScreen}
              loading={loading}
              variant="contained"
              onClick={() => createSubscriptionSession()}
            >
              Go Premium Now
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
      {!subscriptionActive && !isFinalScreen && (
        <>
          <StyledDialogTitle>
            <Typography variant="h5" color={theme.palette.grey[900]}>
              Save {localCurrencySymbol}800 in opportunity costs for only {localCurrencySymbol}
              {price}!
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    padding: '28px',
                    borderRadius: '12px',
                    backgroundColor: '#FEFAEC',
                    paddingBottom: '20px',
                  }}
                >
                  <PriceFeatureTypography variant="body1">
                    Make no mistake - being a freelancer is hard! Difficult clients, less security, no holidays, no sick
                    pay. But nothing beats being your own boss and setting your own agenda.
                  </PriceFeatureTypography>
                  <PriceFeatureTypography variant="body1" mt={2}>
                    Successful freelancers make a habit of working on growth. They have a process and pipeline.
                  </PriceFeatureTypography>
                  <PriceFeatureTypography variant="body1" mt={2}>
                    Shoutt makes life a little bit easier with quick and easy access to 1000+ curated opportunities from
                    30+ sources each month.
                  </PriceFeatureTypography>
                  <PriceFeatureTypography variant="body1" mt={2}>
                    We spend 40 hours per week compiling these opportunities. If you spend say 1 hour per day searching
                    for jobs and your time is worth say {localCurrencySymbol}40 / hour, the opportunity cost to you
                    would be over {localCurrencySymbol}800 / month.
                  </PriceFeatureTypography>
                  <PriceFeatureTypography variant="body1" mt={2}>
                    Our service costs less than a pizza and includes a bunch of extra tools - it’s incredible value!
                  </PriceFeatureTypography>
                </Box>
              </Grid>
            </Grid>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
              Cancel
            </RoundButton>
            <RoundButton
              fullWidth={isMobileScreen}
              loading={loading}
              variant="contained"
              onClick={() => setIsFinalScreen(true)}
            >
              Continue
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
      {subscriptionActive && props.opportunity?.type === OpportunityType.External && (
        <ApplyExternalOpportunity
          open={props.open}
          opportunity={props.opportunity}
          updateApplyOnClose={updateApplyOnClose}
        />
      )}
      {subscriptionActive && props.opportunity?.type === OpportunityType.Shoutt && (
        <ApplyShouttOpportunity
          open={props.open}
          opportunity={props.opportunity}
          onClose={props.onClose}
          updateApplyOnClose={updateApplyOnClose}
        />
      )}
    </Dialog>
  );
}
