import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { showError } from 'utils/errorHandler';

import { redirectToSignUpSeller, useTitle } from 'utils/router';
import { IOpportunity } from 'global/interfaces/opportunity';
import { getOpportunities } from 'services/opportunityService';

import { SelectItem } from 'global/interfaces/selects';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { AuthContext } from 'contexts/AuthContext';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ApplyDialog from 'components/opportunities/ApplyDialog';

import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import { processOpportunityCategories } from 'components/opportunities/categories';
import { RoundButton } from 'components/common/Button/RoundButton';
import EastIcon from '@mui/icons-material/East';
import {
  BannerStack,
  FeatureSubText,
  FeatureText,
  MultiLineFeatureContainer,
  StyledBannerBox,
} from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import OppFilters from 'components/opportunities/OppFilters';
import { pushToDataLayer } from 'utils/tagHelper';
import { applyOpportunityLogin } from 'global/constants';
import userIm from 'assets/images/user/Imogen.png';

const ViewOpportunities = () => {
  useTitle('New Opportunities');
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams] = useSearchParams();
  const urlCategory = searchParams.get('category');

  const [category, setCategory] = useState<string>('All');

  const [categories, setCategories] = useState<SelectItem[]>([{ id: 'All', label: 'All' }]);

  const navigate = useNavigate();
  const location = useLocation();
  const authContext = useContext(AuthContext);
  const loggedIn: boolean = authContext.user !== null;

  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity | null>(null);
  const [applyDialogOpen, setApplyDialogOpen] = useState<boolean>(false);

  const processCategories = (retrievedOpps: IOpportunity[]) => {
    const items = processOpportunityCategories(retrievedOpps);
    setCategories(items);
  };

  const applyNow = (opportunity: IOpportunity): void => {
    setSelectedOpportunity(opportunity);
    if (!loggedIn) {
      pushToDataLayer(applyOpportunityLogin, {
        transaction_id: opportunity.id,
      });
      redirectToSignUpSeller(navigate, location);
    } else {
      setApplyDialogOpen(true);
    }
  };

  const onApplyClose = (): void => {
    setSelectedOpportunity(null);
    setApplyDialogOpen(false);
  };

  const updateOpportunity = (opportunity: IOpportunity): void => {
    setOpportunities(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
    setFilteredOpps(current => current.map(c => (c.id == opportunity.id ? opportunity : c)));
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewOpportunity],
    (res: IOpportunity) => {
      setOpportunities(current => [res, ...current]);
      if (category === 'All' || !category || category == '' || res.categories.includes(category)) {
        setFilteredOpps(current => [res, ...current]);
      }
    },
    [category],
  );

  useEffect(() => {
    processCategories(opportunities);
    if (
      urlCategory !== undefined &&
      urlCategory !== null &&
      urlCategory !== '' &&
      firstLoad &&
      opportunities.length > 0
    ) {
      setCategory(urlCategory);
      const filt = opportunities.filter(p => p.categories.includes(urlCategory));
      setFilteredOpps(filt);
      setFirstLoad(false);
    }
  }, [opportunities]);

  useEffect(() => {
    setLoading(true);
    getOpportunities()
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
        setFilteredOpps(retrievedOpps);
        setLoading(false);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <StyledBannerBox>
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={2}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h3"
                  fontWeight={700}
                  fontSize={{ xs: '26px', md: '44px', lg: '55px' }}
                  color={'#101828'}
                  maxWidth={'800px'}
                >
                  1000+ curated freelance opportunities each month
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={{ xs: '16px', lg: '20px' }}
                  color={'#344054'}
                  maxWidth={'800px'}
                  mt={isMobile ? 2 : 3}
                >
                  Get easy access to fresh, curated and good quality opportunities for less than the cost of a pizza!
                  There are{' '}
                  <b>
                    {!loading ? (
                      opportunities.length
                    ) : (
                      <Skeleton
                        variant="text"
                        width={isMobile ? '50px' : '80px'}
                        component={'span'}
                        sx={{ display: 'inline-block' }}
                      />
                    )}{' '}
                  </b>
                  live right now.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack direction={'row'} spacing={2} mt={isMobile ? 2 : undefined}>
                  <Avatar
                    sx={{
                      width: isMobile ? '100px' : '146px',
                      height: isMobile ? '100px' : '146px',
                      border: '1px solid #A28CF3',
                      mt: isMobile ? 1.5 : undefined,
                    }}
                    src={userIm}
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobile ? '16px' : '20px'}
                    >
                      Imogen H
                    </Typography>
                    <Typography variant="body2" fontWeight={400} color={'#475467'}>
                      Marketing Manager
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      fontSize={{ xs: '18px', md: '22px', lg: '24px' }}
                      lineHeight={{ xs: '22px', md: '26px', lg: '28px' }}
                      fontStyle={'italic'}
                      color={'#2B1161'}
                      mt={1}
                    >
                      &quot;I’ve landed two amazing long term clients&quot;
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={{ xs: '0px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <ClockIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Save 40 Hours</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      We spend 40 hours per week curating opportunities from 30+ sources, saving you precious time.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <BriefIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Simple and Convenient</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      Our live feed makes it fast and easy to browse opps in one place. All opportunities are max 7 days
                      old.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'} height={'100%'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <ExclusiveIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Free Profile</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      List your profile on our marketplace for free to be discovered by new clients, no bidding
                      required.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
            </Grid>
          </BannerStack>
        </Container>
      </StyledBannerBox>
      <Container maxWidth="lg">
        <Grid container>
          <OppFilters
            categories={categories}
            category={category}
            opportunities={opportunities}
            setCategory={setCategory}
            setFilteredOpps={setFilteredOpps}
          />
          {loading && (
            <Grid item xs={12} sx={{ mb: 5 }}>
              <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!loading && (
            <Grid item xs={12} sx={{ mb: 6 }}>
              {filteredOpps.map((opportunity: IOpportunity, index: number) => (
                <OpportunityAccordion key={index} opportunity={opportunity} loggedIn={loggedIn}>
                  <RoundButton
                    variant="contained"
                    endIcon={<EastIcon />}
                    onClick={() => {
                      applyNow(opportunity);
                    }}
                  >
                    Apply Now
                  </RoundButton>
                </OpportunityAccordion>
              ))}
            </Grid>
          )}
        </Grid>
        <ApplyDialog
          open={applyDialogOpen}
          opportunity={selectedOpportunity}
          onClose={onApplyClose}
          updateOpportunity={updateOpportunity}
        />
      </Container>
    </>
  );
};

export default ViewOpportunities;
